import { css } from '@emotion/react';

type Props = {
  open: boolean;
};

const quizWrap = css`
  display: block;
  min-height: 60px;
  
  h5 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    color: #003a70;

    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    max-width: 90%;

    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 32px;
    }
  }
  
  .btn-quiz {
    height: auto;
    box-shadow: none;
    color: red;
    min-width: auto;
  }

  .more {
    color: #0ac9c1;

    :hover {
      color: aqua;
    }
  }

  .less {
    color: #002d57;
  }
`;

const answerWrap = ({ open }: Props) => css`
  display: ${open ? 'flex' : 'none'};
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #303030;
  font-weight: 400;
  line-height: 26px;
  max-width: 615px;

  @media (max-width: 992px) {
    padding-top: 122px;
    .text-right {
      text-align: center !important;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 45px;
    .text-left {
      text-align: center !important;
    }
  }
`;

export default { quizWrap, answerWrap };
