import React from 'react';
import { Icon } from './icon';

type IconProps = {
  className: string;
  size: string;
};

const PlusCircle: React.FC<IconProps> = ({ className, size = '14px' }: IconProps) => (
  <Icon size={size} className={className} iconName="fas fa-plus-circle" />
);

export { PlusCircle };
