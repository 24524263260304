import React from 'react';
import styled from 'styled-components';
import Styles from './icon.style';

type IconProps = {
  className: string;
  size: string;
  iconName: string;
};

const IconWrap = styled.i`
  ${Styles.iconWrap}
`;

const Icon: React.FC<IconProps> = ({
  className,
  iconName,
  size = '24px'
}: IconProps) => (
    <IconWrap
      fontSize={size}
      className={[iconName, className].join(' ')}
    />
  );

export { Icon };
