import React, { useState } from 'react';
import styled from '@emotion/styled';
import Button from '../../../components/Button';
import { MinusCircle, PlusCircle } from '../../../components/icons';

import Styles from './quiz.style';

const QuizWrap = styled.div`
  ${Styles.quizWrap}
`;
const AnswerWrap = styled.p`
  ${Styles.answerWrap}
`;

type QuizProps = {
  question: string;
  answer: string;
};

const Quiz: React.FC<QuizProps> = ({ question, answer }: QuizProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <QuizWrap>
      <div className="row align-items-center">
        <div className="col-11 col-lg-11 align-items-center">
          <h5>{question}</h5>
        </div>
        <div className="col-1 col-lg-1 align-items-center justify-content-center">
          <Button className="btn-quiz" onClick={handleClick}>
            {isOpen ? <MinusCircle size="32px" className="less" /> : <PlusCircle size="32px" className="more" />}
          </Button>
        </div>
      </div>
      <AnswerWrap open={isOpen}>{answer}</AnswerWrap>
    </QuizWrap>
  );
};

export { Quiz };
