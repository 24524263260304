import { css } from 'styled-components';

type Props = {
  fontSize: string;
};

const iconWrap = ({ fontSize }: Props) => css`
  font-size: ${fontSize};
`;

export default { iconWrap };
