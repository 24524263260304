import React from 'react';
import GlobalStyles from './styles/global';
import './assets/sass/app.scss';
import './App.css';
import LandingPage from './scenes/LandingPage';

function App(): React.ReactElement {
  return (
    <>
      <LandingPage />
      <GlobalStyles />
    </>
  );
}

export default App;
